exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adr-js": () => import("./../../../src/pages/adr.js" /* webpackChunkName: "component---src-pages-adr-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-gettingstarted-create-account-js": () => import("./../../../src/pages/gettingstarted/create-account.js" /* webpackChunkName: "component---src-pages-gettingstarted-create-account-js" */),
  "component---src-pages-gettingstarted-deposit-js": () => import("./../../../src/pages/gettingstarted/deposit.js" /* webpackChunkName: "component---src-pages-gettingstarted-deposit-js" */),
  "component---src-pages-gettingstarted-index-js": () => import("./../../../src/pages/gettingstarted/index.js" /* webpackChunkName: "component---src-pages-gettingstarted-index-js" */),
  "component---src-pages-gettingstarted-withdrawl-js": () => import("./../../../src/pages/gettingstarted/withdrawl.js" /* webpackChunkName: "component---src-pages-gettingstarted-withdrawl-js" */),
  "component---src-pages-howtoplay-js": () => import("./../../../src/pages/howtoplay.js" /* webpackChunkName: "component---src-pages-howtoplay-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-justice-js": () => import("./../../../src/pages/justice.js" /* webpackChunkName: "component---src-pages-justice-js" */),
  "component---src-pages-latest-js": () => import("./../../../src/pages/latest.js" /* webpackChunkName: "component---src-pages-latest-js" */),
  "component---src-pages-promotions-drawing-winners-js": () => import("./../../../src/pages/promotions/drawing-winners.js" /* webpackChunkName: "component---src-pages-promotions-drawing-winners-js" */),
  "component---src-pages-promotions-index-js": () => import("./../../../src/pages/promotions/index.js" /* webpackChunkName: "component---src-pages-promotions-index-js" */),
  "component---src-pages-promotions-leaderboards-eth-grinder-js": () => import("./../../../src/pages/promotions/leaderboards/eth-grinder.js" /* webpackChunkName: "component---src-pages-promotions-leaderboards-eth-grinder-js" */),
  "component---src-pages-promotions-leaderboards-gold-js": () => import("./../../../src/pages/promotions/leaderboards/gold.js" /* webpackChunkName: "component---src-pages-promotions-leaderboards-gold-js" */),
  "component---src-pages-promotions-leaderboards-index-js": () => import("./../../../src/pages/promotions/leaderboards/index.js" /* webpackChunkName: "component---src-pages-promotions-leaderboards-index-js" */),
  "component---src-pages-promotions-leaderboards-silver-js": () => import("./../../../src/pages/promotions/leaderboards/silver.js" /* webpackChunkName: "component---src-pages-promotions-leaderboards-silver-js" */),
  "component---src-pages-promotions-leaderboards-sng-chase-js": () => import("./../../../src/pages/promotions/leaderboards/sng-chase.js" /* webpackChunkName: "component---src-pages-promotions-leaderboards-sng-chase-js" */),
  "component---src-pages-redirection-index-js": () => import("./../../../src/pages/redirection/index.js" /* webpackChunkName: "component---src-pages-redirection-index-js" */),
  "component---src-pages-referrals-js": () => import("./../../../src/pages/referrals.js" /* webpackChunkName: "component---src-pages-referrals-js" */),
  "component---src-pages-tokeneconomy-gold-token-js": () => import("./../../../src/pages/tokeneconomy/gold-token.js" /* webpackChunkName: "component---src-pages-tokeneconomy-gold-token-js" */),
  "component---src-pages-tokeneconomy-index-js": () => import("./../../../src/pages/tokeneconomy/index.js" /* webpackChunkName: "component---src-pages-tokeneconomy-index-js" */),
  "component---src-pages-tokeneconomy-nft-avatars-js": () => import("./../../../src/pages/tokeneconomy/nft-avatars.js" /* webpackChunkName: "component---src-pages-tokeneconomy-nft-avatars-js" */),
  "component---src-pages-tokeneconomy-overview-js": () => import("./../../../src/pages/tokeneconomy/overview.js" /* webpackChunkName: "component---src-pages-tokeneconomy-overview-js" */),
  "component---src-pages-tokeneconomy-staking-js": () => import("./../../../src/pages/tokeneconomy/staking.js" /* webpackChunkName: "component---src-pages-tokeneconomy-staking-js" */),
  "component---src-pages-tokeneconomy-vpp-token-js": () => import("./../../../src/pages/tokeneconomy/vpp-token.js" /* webpackChunkName: "component---src-pages-tokeneconomy-vpp-token-js" */),
  "component---src-pages-version-js": () => import("./../../../src/pages/version.js" /* webpackChunkName: "component---src-pages-version-js" */),
  "component---src-pages-whoweare-js": () => import("./../../../src/pages/whoweare.js" /* webpackChunkName: "component---src-pages-whoweare-js" */)
}


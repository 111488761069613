/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';
import crypto from 'crypto';

const styles = {
  wrapper: {
    height: '100vh',
    background: '#20212C',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    width: '100%',
    height: '48px',
    borderRadius: '8px',
    border: 'none',
    padding: '0px',
    letterSpacing: '2px',
    textAlign: 'center',
  },
  button: {
    width: '100%',
    height: '48px',
    backgroundImage: 'linear-gradient(134deg, #32E4BF 0%, #21A1DE 100%)',
    color: '#fff',
    marginTop: '16px',
    textTransform: 'uppercase',
    fontFamily: 'sans-serif',
    boxShadow: '0 18px 20px 0 rgba(27, 28, 37, 0.20)',
    border: 'none',
    borderRadius: '8px',
    fontWeight: 'bold',
    fontSize: '18px',
    letterSpacing: '0.25px',
    padding: '0px',
  },
  buttonHover: {
    filter: 'brightness(120%)',
  },
  title: {
    background: 'linear-gradient(134deg, #00FFCC, #0040FF)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
  },
};

const PasswordProtect = () => {
  const [password, setPassword] = useState('');
  const [isButtonHovered, buttonHover] = useState(false);

  const onSubmit = event => {
    event.preventDefault();

    const passwordHash = crypto.createHash('md5').update(password).digest("hex");
    setSessionPassword(passwordHash);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <div style={styles.wrapper}>
      <h1 style={{ ...styles.title }}>Virtue Poker</h1>
      <h4 style={{ color: '#fff' }}>Enter Password</h4>

      <form onSubmit={onSubmit} style={{ width: '320px' }}>
        <input
          name="password"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          style={styles.input}
        />

        <button
          type="submit"
          style={{
            ...styles.button,
            ...(isButtonHovered ? styles.buttonHover : null)
          }}
          onMouseEnter={() => buttonHover(true)}
          onMouseLeave={() => buttonHover(false)}
        >
          Go Site
        </button>
      </form>
    </div>
  );
};

export default PasswordProtect;
